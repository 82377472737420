.icon {
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
}

.icon-large {
  @extend .icon;
  width: 48px;
  height: 48px;
}

.icon-medium {
  @extend .icon;
  width: 24px;
  height: 24px;
}

.icon-small {
  @extend .icon;
  width: 12px;
  height: 12px;
}

.icon-16 {
  @extend .icon;
  width: 16px;
  height: 16px;
}

.icon-20 {
  @extend .icon;
  width: 20px;
  height: 20px;
}

.icon-24 {
  @extend .icon;
  width: 24px;
  height: 24px;
}

.icon-32 {
  @extend .icon;
  width: 32px;
  height: 32px;
}

.icon-36 {
  @extend .icon;
  width: 36px;
  height: 36px;
}

.icon-40 {
  @extend .icon;
  width: 40px;
  height: 40px;
}

.icon-50 {
  @extend .icon;
  width: 50px;
  height: 50px;
}

.icon-64 {
  @extend .icon;
  width: 64px;
  height: 64px;
}

.icon-72 {
  @extend .icon;
  width: 72px;
  height: 72px;
}

.icon-menu {
  @extend .icon;
  background-image: url(../assets/icons/ic-menu.svg);
}

.icon-arrow-back {
  @extend .icon;
  background-image: url(../assets/icons/ic-arrow-back.svg);
}

.icon-keyboard-arrow-right {
  @extend .icon;
  background-image: url(../assets/icons/ic-keyboard-arrow-right.svg);
}

.icon-show-more {
  @extend .icon;
  background-image: url(../assets/icons/ic-show-more.svg);
}

.icon-sort {
  @extend .icon;
  background-image: url(../assets/icons/ic-sort.svg);
}

.icon-filter {
  @extend .icon;
  background-image: url(../assets/icons/ic-filter.svg);
}

.icon-profile {
  @extend .icon;
  background-image: url(../assets/icons/ic-user-profile.svg);
}

.icon-arrow-up {
  @extend .icon;
  background-image: url(../assets/icons/ic-arrow-up.svg);
}

.icon-add {
  @extend .icon;
  background-image: url(../assets/icons/ic-add.svg);
}

.icon-ellipse-3 {
  @extend .icon;
  background-image: url(../assets/icons/ic-ellipse-3.svg);
}

.icon-close {
  @extend .icon;
  background-image: url(../assets/icons/ic-close.svg);
}

.icon-arrow-drop-down {
  @extend .icon;
  background-image: url(../assets/icons/arrow_drop_down.svg);
}

.icon-warning {
  @extend .icon;
  background-image: url(../assets/icons/warning.svg);
}

.icon-danger {
  @extend .icon;
  background-image: url(../assets/icons/danger.svg);
}

.icon-delete {
  @extend .icon;
  background-image: url(../assets/icons/ic-delete.svg);
}

.icon-google {
  @extend .icon;
  background-image: url(../assets/icons/google.svg);
}
