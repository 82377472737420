@import "typography";
@import "icon";
@import "helper";
@import "fonts";

$black80: rgba(0, 0, 0, 0.8);

.modal-portal {
  .active,
  .modal-backdrop {
    z-index: 2000;
  }
  .modal-content {
    z-index: 3000;
    padding: 0px !important;
  }
}

// For sticky header section
.header-wrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.react-datepicker__portal {
  .react-datepicker__day--highlighted {
    color: rgba(153, 153, 153, 1) !important;
    background-color: #ffffff;
    border-radius: 0;
    border-bottom: 2px solid rgba(24, 142, 173, 1);
  }
  .react-datepicker__day--disabled {
    color: rgba(153, 153, 153, 1) !important;
  }
}

.Toastify__toast-container {
  color: $black80 !important;
  line-height: 1.43;
  // width: unset !important;
}

.Toastify__toast-container--bottom-left {
  left: 100px !important;
}

.Toastify__toast-container--top-right {
  right: 100px !important;
}

.Toastify__toast {
  min-height: unset !important;
  border-radius: 4px !important;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2) !important;
  color: black;
  padding: 14px 16px !important;
}

.Toastify__toast--error {
  background-color: rgba(253, 239, 238, 1) !important;
  color: rgba(230, 90, 83, 1);
}

.Toastify__toast--success {
  background-color: #e1fbeb !important;
}

.calender-icon {
  position: absolute;
  top: 42px !important;
  right: 10px !important;
}
