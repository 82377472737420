*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba($color: #000000, $alpha: 0.8);
  margin: 0;
  padding: 0;
  background-color: #e5e5e5;
}
input,
textarea {
  font-family: Inter, sans-serif;
}
.subtext {
  font-size: 10px;
  line-height: 14px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

// text-transform

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.normal-case {
  text-transform: none;
}
.underline {
  text-decoration: underline;
}

// line-height

.line-height-1 {
  line-height: 1;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-0_5 {
  letter-spacing: 0.5px;
}

.letter-spacing-n1 {
  letter-spacing: -1px;
}

.letter-spacing-n0_5 {
  letter-spacing: -0.5px;
}

// overflow

.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-visible {
  overflow-x: visible;
}
.overflow-y-visible {
  overflow-y: visible;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}

// cursor

.cursor-auto {
  cursor: auto;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-wait {
  cursor: wait;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.pointer-events-none {
  pointer-events: none;
}

// word-break
.word-break-break-all {
  word-break: break-all;
}

//toast
.Toastify__toast-theme--light {
  background: transparent;
}
.Toastify__toast {
  box-shadow: none;
}
