.heading1 {
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
}

.heading2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.heading3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.paragraph1 {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

.paragraph2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.label1 {
  //styleName: Label 1;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #444444;
}

.all-caps-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.caption {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.link1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.link2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
